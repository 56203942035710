import React, { useState } from "react";
import WidgetTable from "./WidgetTable";
import WidgetScorecard from "./WidgetScorecard";
import { callGaEvent } from "constants/cricket";
import PinkScoreCard from "./ipl/PinkScoreCard";


const WidgetHomeIpl = ({
  newsData,
  table,
  size,
  pointsData,
  scoreData,
  getStatus,
}) => {
  const IMAGE_BASE_URL = `https://akm-img-a-in.tosshub.com/sites/live-score/resources-live/images/flag_img_c/v2/`;


  const [currentIndex, setCurrentIndex] = useState(0);
  const colors = ["green", "blue", "red", "yellow"];
  return (
    <>
      {size.width ? size.width <= 768 ? (
        <div
          style={{
            width: "100%",
            maxWidth: "768px",
            margin: "0 auto",
            backgroundColor: "#016369",
          }}
          className="mt-4"
        >
          
          <PinkScoreCard scoreData={scoreData}/>


          <div
            className="d-flex justify-content-center align-items-center gap-3 py-3 "
          >
            <a href="/sports/ipl">
            <button
              onClick={() => {
                const obj = {
                  eventName: "Hero_Widget_CTA_Click",
                  pageTitle: "Home Page",
                };
                callGaEvent(obj);

                // window.location.href = "/topic/ipl";
              }}
              style={{
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid #FFFFFF4D",
                borderRadius: "10px",
                padding: "5px 8px",
                cursor: "pointer",
              }}
            >
              <span class="custom-external-link">न्यूज़ ↗</span>
            </button>
            </a>
            <a href = '/sports/ipl/points-table'>
            <button
              style={{
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid #FFFFFF4D",
                borderRadius: "10px",
                padding: "5px 8px",
                cursor: "pointer",
              }}
              onClick={() => {
                const obj = {
                  eventName: "Hero_Widget_CTA_Click",
                  pageTitle: "Home Page",
                };
                callGaEvent(obj);
                // window.location.href =
                //   "/sports/cricket/ipl/points-table";
              }}
            >
              <span class="custom-external-link">पॉइंट्स टेबल ↗</span>
            </button>
            </a>
            <a href="/sports/ipl/schedule">
            <button
              style={{
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid #FFFFFF4D",
                borderRadius: "10px",
                padding: "5px 8px",
                cursor: "pointer",
              }}
              onClick={() => {
                const obj = {
                  eventName: "Hero_Widget_CTA_Click",
                  pageTitle: "Home Page",
                };
                callGaEvent(obj);
                // window.location.href =
                //   "/sports/cricket/ipl/schedule";
              }}
            >
              <span class="custom-external-link">शेड्यूल ↗</span>
            </button>
            </a>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#016369",
            color: "white",
            padding: "20px",
            borderRadius: "10px",
            margin: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundImage: "url('/assets/images/ipl_microsite/ipl_banner.jpg')",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Left Section */}
          {/* <div
        style={{
          flex: 1,
          backgroundColor: "#327d7f",
          padding: "15px",
          borderRadius: "10px",
          marginRight: "10px",
        }}
      > */}
          <div
            onClick={() => {
              const obj = {
                eventName: "Hero_Match_Card_Click",
                pageTitle: "Home Page",
              };
              callGaEvent(obj);
            }}
            style={{
              flex: 1,
            }}
          >
            <WidgetScorecard
              item={scoreData}
              getStatus={getStatus}
              scoreData={scoreData}
            />
          </div>



          {/* Center Section */}
          <div
            style={{
              textAlign: "center",
              flex: 1,
              margin: "0 10px",
              fontSize: "16px",
            }}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <div
              style={{
                margin: 0,
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                width: "150px",
                height: "92px"
              }}
            >
              <img
                src={"https://static.thelallantop.com/images/post/1742890145327_rectangle_669-min.webp"}
                style={{ paddingTop: "0px" }}
                className="w-100 h-100"
              />

            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <a href="/sports/ipl">
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid #FFFFFF4D",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Widget_CTA_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                  // window.location.href = "/topic/ipl-2025";
                }}
              >
                न्यूज़ <span class="custom-external-link">↗</span>
              </button>
              </a>
              <a href="/sports/ipl/points-table">
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid #FFFFFF4D",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Widget_CTA_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                  // window.location.href =
                  //   "/sports/cricket/ipl/points-table";
                }}
              >
                पॉइंट्स टेबल <span class="custom-external-link">↗</span>
              </button>
              </a>
              <a href="/sports/ipl/schedule">
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid #FFFFFF4D",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const obj = {
                    eventName: "Hero_Widget_CTA_Click",
                    pageTitle: "Home Page",
                  };
                  callGaEvent(obj);
                  // window.location.href =
                  //   "/sports/cricket/ipl/schedule";
                }}
              >
                शेड्यूल <span class="custom-external-link">↗</span>
              </button>
              </a>
            </div>
          </div>

          {/* Right Section */}
          <div
            style={{
              flex: 1,
            }}
          // style={{
          //   flex: 1,
          //   backgroundColor: "#327d7f",
          //   padding: "15px",
          //   borderRadius: "10px",
          //   marginLeft: "10px",
          // }}
          >
            {/* <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Teams (Group A)
        </div> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Team Name 1</span>
          <span>2 2 0 0 2</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Team Name 2</span>
          <span>2 1 0 1 2</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Team Name 3</span>
          <span>2 0 1 1 0</span>
        </div> */}
            <WidgetTable
              pointsData={pointsData}
              desktop={false}
              table={table}
              size={size}
              IMAGE_BASE_URL={IMAGE_BASE_URL}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WidgetHomeIpl;
