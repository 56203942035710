import axios from 'axios';
import { SERIES_ID_IPL, TEAMS_OBJ } from 'constants/cricket';
import React, { useEffect, useMemo, useState } from 'react'

function PinkScoreCard({ scoreData }) {


  //PRIORITY 
  // live , recent , upcoming 


  const [matchData, setMatchData] = useState(null);
  // const [scoreData , setScoreData] = useState(null)
  const [isLive, setIsLive] = useState(false);
  const [liveId, setLiveId] = useState(null);
  const [liveData, setLiveData] = useState(null);
  const [upcoming, setIsUpoming] = useState(false)
  const [recent, setIsRecent] = useState(false)


  const sortByDate = (matches) => {
    return matches.sort(
      (a, b) => new Date(a.matchdate_ist) - new Date(b.matchdate_ist)
    );
  };


  const filterBySeries = (allMatches) => {
    return allMatches.filter((match) => match.series_Id == SERIES_ID_IPL);
  };

  const onCardClick = async (matchId) => {
    
    //fetch the status of the match
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_URL
      }/api/getRecentMatch?id=${matchId}&nocache=${new Date().getTime()}`
    );
    let fetchedData = response?.data ?? null;
    if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
      const data = fetchedData?.data?.response?.scorecard;
      let page = null
      if (data.is_live == 1) {
        page = 'live'
      }
      else if (data.upcoming == 1) {
        page = 'info'
      }
      else {
        page = 'summary'
      }
      window.location.href = `/sports/ipl/${data?.page_slug_st}/stats/${page}`

    }

  }


  useEffect(() => {
    // const live = false
    //match is live
    if (
      scoreData?.live &&
      scoreData?.live?.channel?.live?.length > 0 &&
      filterBySeries(scoreData?.live?.channel?.live).length > 0
    ) {
      setIsLive(true);
      setIsUpoming(false)
      setIsRecent(false)
      const data = sortByDate(filterBySeries(scoreData?.live?.channel?.live));
      setMatchData(data[0]);
      setLiveId(data[0]?.match_Id);

      setLiveData({
        seriesName: data[0]?.series_name,
        matchNumber: data[0]?.matchnumber,
        teamAId: data[0]?.teama_Id,
        teamBId: data[0]?.teamb_Id,
        whoIsBowling: data[0]?.inn_team_1,
        whoIsBatting: data[0]?.inn_team_2,
        bowlingTeamScore: data[0]?.inn_score_1,
        battingTeamScore: data[0]?.inn_score_2,
        teamALogo: data[0]?.teama_flag,
        teamBLogo: data[0]?.teamb_flag,
        teamAName: data[0]?.teama_hn,
        teamAShort: data[0]?.teama_short,
        teamBShort: data[0]?.teamb_short,
        teamBName: data[0]?.teamb_hn,
        matchStatus: data[0]?.matchresult,
        matchResult: data[0]?.matchstatus,
        pageSlugSt: data[0]?.page_slug_st,
        showStar:
          data[0]?.inn_team_1 == data[0]?.teama_Id
            ? data[0]?.teama_Id
            : data[0]?.teamb_Id,
      });
    }

    //match is recent
    else if (scoreData?.recent && scoreData?.recent?.count > 0) {
      setMatchData(scoreData.recent.data[0]);
      setIsLive(false);
      setIsUpoming(false)
      setIsRecent(true)
      setLiveId(null);
    }
    //match is upcoming
    else if (scoreData?.upcoming && scoreData?.upcoming?.count > 0) {
      setMatchData(sortByDate(scoreData.upcoming.data)[0]);
      setIsLive(false);
      setIsUpoming(true)
      setIsRecent(false)
      setLiveId(null);
    }

  }, []);

  useEffect(() => {
    if (liveId) {
      const poolingLiveMatches = async () => {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_BASE_URL
          }/api/getRecentMatch?id=${liveId}&nocache=${new Date().getTime()}`
        );
        let fetchedData = response?.data ?? null;

        if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
          // // setLiveData(fetchedData?.data?.response?.scorecard);
          // //update the array of carousel
          // setCarouselMatches(carouselMatches?.map(it => {
          //   if(it.channel.scorecard.match_Id == fetchedData?.data?.response?.scorecard?.match_Id){
          //     return item.channel.scorecard
          //   }
          //   else{
          //     return it
          //   }
          // }))
          const data = fetchedData?.data?.response?.scorecard;
          //set the match data of the live match
          setMatchData(data);
          setLiveData({
            seriesName: data?.seriesname,
            matchNumber: data?.matchnumber,
            teamAId: data?.teama_Id,
            teamBId: data?.teamb_Id,
            whoIsBowling: data?.current_bowling_team_id,
            whoIsBatting: data?.current_batting_team,
            bowlingTeamScore: data?.bowlingTeamStats,
            battingTeamScore: data?.battingTeamStats,
            teamALogo: data?.teama_logo,
            teamBLogo: data?.teamb_logo,
            teamAName: data?.teama_hn,
            teamBName: data?.teamb_hn,
            teamAShort: data?.teama_short,
            teamBShort: data?.teamb_short,
            matchStatus: data?.matchresult_hn,
            matchResult: data?.matchStatus_hn,
            pageSlugSt: data?.page_slug_st,
            showStar:
              data?.current_bowling_team_id == data?.teama_Id
                ? data?.teamb_Id
                : data?.teama_Id,
          });
        } else {
          // alert("Please refresh the page.");
          // Refresh the page after user clicks OK
          // window.location.reload();
        }
      };

      let interval = setInterval(() => {
        poolingLiveMatches();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [liveId]);


  const Live = ({ it, api = "common" }) => {


    function splitString(input) {
        let index = input.indexOf('(');
        if (index === -1) return [input, '']; // If '(' is not found
  
        let before = input.substring(0, index).trim();
        let after = input.substring(index).trim();
  
        return [before, after];
      }
    return <div style={{ cursor: "pointer" }} onClick={() => {
      onCardClick(matchData?.match_Id)
    }} className="row align-items-center h-100 me-1">
    <div className="col-3 ">
      <div className="d-flex justify-content-center align-items-center" >
        <img alt="img_ipl_banner" src="https://static.thelallantop.com/images/post/1742890145327_rectangle_669-min.webp" className='w-75 h-100' />
      </div>
    </div>
    <div className="col-6 h-100 position-relative p-0"
       style={{ backgroundColor: "#EB0E8C",  }}
      >
          <div className="position-absolute " style={{  height: "6px", top: "-17%", left: "-4px",width:"calc(100% + 8px)" }}>
            <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/top_line.png" className='w-100 h-100' />
          </div>
        <div className="h-100 " style={{ backgroundColor: "#EB0E8C", width: "100%" , padding : "0px 10px" }}>
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="d-flex align-items-center gap-1">
              <div style={{ width: "30px", height: "30px" }}>
                <img alt="img_ipl_banner" src={TEAMS_OBJ[it?.teamAShort] ? TEAMS_OBJ[it?.teamAShort] : it?.teamALogo} className='w-100 h-100' />
              </div>
              <div>
                <p className="m-0 p-0 text-white" style={{ fontWeight: 700, fontSize: "12px" }}>{it?.teamAId == it?.whoIsBowling
                  ? splitString(it?.bowlingTeamScore)[0]
                  : splitString(it?.battingTeamScore)[0]}{" "}
                  {it?.showStar == it?.teamAId && " *"}</p>
                  <p className="m-0 p-0 text-white" style={{ fontWeight: 400, fontSize: "8px" }}>{it?.teamAId == it?.whoIsBowling
                  ? splitString(it?.bowlingTeamScore)[1]
                  : splitString(it?.battingTeamScore)[1]}{" "}
                  </p>
                  
                {/* <p className="m-0 p-0" style={{ fontWeight: 400, fontSize: "11px", color: "#FFFFFFCC" }}>75/2*</p> */}
              </div>
            </div>
            <p style={{ fontWeight: 400, fontSize: "10px", color: "#FFFFFF80" }}>v/s</p>
            <div className="d-flex align-items-center gap-1">
              
              <div>
                <p className="m-0 p-0 text-white" style={{ fontWeight: 700, fontSize: "12px" }}>{it?.teamBId == it?.whoIsBowling
                  ? splitString(it?.bowlingTeamScore)[0]
                  : splitString(it?.battingTeamScore)[0]}{" "}
                  {it?.showStar == it?.teamBId && " *"}</p>
                  <p className="m-0 p-0 text-white" style={{ fontWeight: 400, fontSize: "8px" }}>{it?.teamBId == it?.whoIsBowling
                  ? splitString(it?.bowlingTeamScore)[1]
                  : splitString(it?.battingTeamScore)[1]}{" "}
                </p>
                {/* <p className="m-0 p-0" style={{ fontWeight: 400, fontSize: "11px", color: "#FFFFFFCC" }}>75/2*</p> */}
              </div>
              <div style={{ width: "30px", height: "30px" }}>
                <img alt="img_ipl_banner" src={TEAMS_OBJ[it?.teamBShort] ? TEAMS_OBJ[it?.teamBShort] : it?.teamBLogo} className='w-100 h-100' />
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#FFFFFF4D", height: "1px" }} className="w-100 my-2"></div>
          <p className="m-0 p-0 text-white text-center" style={{ fontWeight: 400, fontSize: "10px" }}>{it.matchResult ? it.matchResult : it.matchStatus}</p>
        </div>
        <div className="position-absolute " style={{  height: "6px", bottom: "5%", left: "-4px",width:"calc(100% + 8px)" }}>
            <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/btm.png" className='w-100 h-100' />
          </div>
      </div>
      <div className="col-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="m-0 p-0 text-white" style={{ fontSize: "10px", fontWeight: "600" }}>IPL 2025</p>
          <p className="m-0 p-0" style={{ fontSize: "10px", fontWeight: "600", color: "#FFFFFF80" }}>{it?.matchNumber}</p>
          <div className="d-flex align-items-center gap-1">
            <p className="m-0 p-0" style={{ fontSize: "8px", fontWeight: "600", color: "#70CBD0" }}>Match Center</p>

            <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.874023 0.728516L2.80605 2.60571L0.874023 4.68051" stroke="#70CBD0" stroke-width="0.987998" stroke-linecap="round" />
            </svg>

          </div>
        </div>
      </div>
    </div>
  };
  const Upcoming = ({ it }) => {


    const imgA = useMemo(() => it.teama_flag, [])
    const imgB = useMemo(() => it.teamb_flag, [])

    return <div style={{ cursor: "pointer" }} onClick={() => {
      onCardClick(matchData?.match_id)
    }} className="row align-items-center h-100 me-1">
      <div className="col-3 ">
        <div className="d-flex justify-content-center align-items-center" >
          <img alt="img_ipl_banner" src="https://static.thelallantop.com/images/post/1742890145327_rectangle_669-min.webp" className='w-75 h-100' />
        </div>
      </div>

      <div className="col-6 h-100 position-relative p-0"
        style={{ backgroundColor: "#EB0E8C", }}
      >
        <div className="position-absolute " style={{ height: "6px", top: "-17%", left: "-4px", width: "calc(100% + 8px)" }}>
          <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/top_line.png" className='w-100 h-100' />
        </div>
        <div className="h-100">
          <div className="d-flex justify-content-center align-items-center pt-1 gap-4">
            <div className="d-flex align-items-center gap-1">
              <div style={{ width: "30px", height: "30px" }}>
                <img alt="img_ipl_banner" src={TEAMS_OBJ[it?.teama_short] ? TEAMS_OBJ[it?.teama_short] : "/assets/images/ipl_microsite/team.png"} className='w-100 h-100' />
              </div>
            </div>
            <p style={{ fontWeight: 400, fontSize: "10px", color: "#FFFFFF80" }}>v/s</p>
            <div className="d-flex align-items-center gap-1">
              <div style={{ width: "30px", height: "30px" }}>
                <img alt="img_ipl_banner" src={TEAMS_OBJ[it?.teamb_short] ? TEAMS_OBJ[it?.teamb_short] : "/assets/images/ipl_microsite/team.png"} className='w-100 h-100' />
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#FFFFFF4D", height: "1px" }} className="w-100 my-2"></div>
          <p className="m-0 p-0 text-white text-center" style={{ fontWeight: 400, fontSize: "10px" }}>{it.matchresult ? it.matchresult : it.matchstatus}</p>
        </div>
        <div className="position-absolute " style={{ height: "6px", bottom: "5%", left: "-4px", width: "calc(100% + 8px)" }}>
          <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/btm.png" className='w-100 h-100' />
        </div>
        {/* <div className="mx-2 position-absolute" style={{ width: "224px", height: "6px", bottom: "3%", left: "0px" }}>
            <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/btm.png" className='w-100 h-100' />
          </div> */}
      </div>
      <div className="col-3 ">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="m-0 p-0 text-white" style={{ fontSize: "10px", fontWeight: "600" }}>IPL 2025</p>
          <p className="m-0 p-0" style={{ fontSize: "10px", fontWeight: "600", color: "#FFFFFF80" }}>{it?.matchnumber}</p>
          <div className="d-flex align-items-center gap-1">
            <span className="m-0 p-0 text-center" style={{ fontSize: "8px", fontWeight: "600", color: "#70CBD0" }}>Match Center</span>
            <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.874023 0.728516L2.80605 2.60571L0.874023 4.68051" stroke="#70CBD0" stroke-width="0.987998" stroke-linecap="round" />
            </svg>

          </div>
        </div>
      </div>
    </div>
  };

  const Recent = ({ it, api = "common" }) => {

    function splitString(input) {
      let index = input.indexOf('(');
      if (index === -1) return [input, '']; // If '(' is not found

      let before = input.substring(0, index).trim();
      let after = input.substring(index).trim();

      return [before, after];
    }
    return <div style={{ cursor: "pointer" }} onClick={() => {
      onCardClick(it?.match_id)
    }} className="row align-items-center h-100 me-1">
      <div className="col-3 ">
        <div className="d-flex justify-content-center align-items-center" style={{paddingLeft : "10px"}} >
          <img alt="img_ipl_banner" src="https://static.thelallantop.com/images/post/1742890145327_rectangle_669-min.webp" className='w-85 h-100' />
        </div>
      </div>
      <div className="col-6 h-100 position-relative p-0"
        style={{ backgroundColor: "#EB0E8C", }}
      >
        <div className="position-absolute " style={{ height: "6px", top: "-17%", left: "-4px", width: "calc(100% + 8px)" }}>
          <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/top_line.png" className='w-100 h-100' />
        </div>
        <div className="h-100 " style={{ backgroundColor: "#EB0E8C", width: "100%" , padding : "0px 10px" }}>
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="d-flex align-items-center gap-1">
              <div style={{ width: "30px", height: "30px" }}>
                <img alt="img_ipl_banner" src={TEAMS_OBJ[it?.teama_short] ? TEAMS_OBJ[it?.teama_short] : "/assets/images/ipl_microsite/team.png"} className='w-100 h-100' />
              </div>
              <div>
                <div style={{display : "flex"}}>

                
                <span className="m-0 p-0 text-white" style={{ fontWeight: 700, fontSize: "12px" , whiteSpace : "nowrap" }}>{it?.teama_id == it?.inn_team_1 ? splitString(it?.inn_score_1.replace(/\//g, '-'))[0] : splitString(it?.inn_score_2.replace(/\//g, '-'))[0]} {it?.showStar == it?.teama_id && " *"}
                
                </span>
                {it?.winningteam_id == it?.teama_id && <span style={{maxHeight : "10px"}}> <img src={'/assets/images/trophy.gif'} /></span>}
                </div>
                <p className="m-0 p-0 text-white" style={{ fontWeight: 400, fontSize: "8px" , whiteSpace : "nowrap" }}>{it?.teama_id == it?.inn_team_1 ? splitString(it?.inn_score_1.replace(/\//g, '-'))[1] : splitString(it?.inn_score_2.replace(/\//g, '-'))[1]} {it?.showStar == it?.teama_id && " *"}</p>

                {/* <p className="m-0 p-0" style={{ fontWeight: 400, fontSize: "11px", color: "#FFFFFFCC" }}>75/2*</p> */}
              </div>
              
            </div>
            <p style={{ fontWeight: 400, fontSize: "10px", color: "#FFFFFF80" }}>v/s</p>
            <div className="d-flex align-items-center gap-1">
              <p className="m-0 p-0 text-white" style={{ fontWeight: 700, fontSize: "12px", textAlign: "end", whiteSpace : "nowrap" }}>{(it?.winningteam_id == it?.teamb_id) && <img style={{maxHeight : "20px"}} src={'/assets/images/trophy.gif'} />} {it?.teamb_id == it?.inn_team_1 ? splitString(it?.inn_score_1.replace(/\//g, '-'))[0] : splitString(it?.inn_score_2.replace(/\//g, '-'))[0]} {it?.showStar == it?.teamb_id && " *"}
                <p className="m-0 p-0 text-white" style={{ fontWeight: 400, fontSize: "8px", whiteSpace : "nowrap" }}>{it?.teamb_id == it?.inn_team_1 ? splitString(it?.inn_score_1.replace(/\//g, '-'))[1] : splitString(it?.inn_score_2.replace(/\//g, '-'))[1]} {it?.showStar == it?.teamb_id && " *"}</p>

              </p>
              <div style={{ width: "30px", height: "30px" }}>
                <img alt="img_ipl_banner" src={TEAMS_OBJ[it?.teamb_short] ? TEAMS_OBJ[it?.teamb_short] : "/assets/images/ipl_microsite/team.png"} className='w-100 h-100' />
              </div>
              <div>

                {/* <p className="m-0 p-0" style={{ fontWeight: 400, fontSize: "11px", color: "#FFFFFFCC" }}>75/2*</p> */}
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#FFFFFF4D", height: "1px" }} className="w-100 my-2"></div>
          <p className="m-0 p-0 text-white text-center" style={{ fontWeight: 400, fontSize: "10px" }}>{it.matchresult ? it.matchresult : it.matchstatus}</p>
        </div>
        <div className="position-absolute " style={{ height: "6px", bottom: "5%", left: "-4px", width: "calc(100% + 8px)" }}>
          <img alt="img_ipl_banner" src="/assets/images/ipl_microsite/btm.png" className='w-100 h-100' />
        </div>
      </div>
      <div className="col-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="m-0 p-0 text-white" style={{ fontSize: "10px", fontWeight: "600" }}>IPL 2025</p>
          <p className="m-0 p-0" style={{ fontSize: "10px", fontWeight: "600", color: "#FFFFFF80" }}>{it?.matchnumber}</p>
          <div className="d-flex align-items-center gap-1">
            <p className="m-0 p-0" style={{ fontSize: "8px", fontWeight: "600", color: "#70CBD0" }}>Match Center</p>

            <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.874023 0.728516L2.80605 2.60571L0.874023 4.68051" stroke="#70CBD0" stroke-width="0.987998" stroke-linecap="round" />
            </svg>

          </div>
        </div>
      </div>
    </div>
  };



  return (
    matchData ?
      <div style={{
        backgroundImage: "url('/assets/images/ipl_microsite/ipl_banner.jpg')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "75px",

      }}>{
          isLive ? (
            <Live it={liveData} />
          ) : upcoming ? (
            <Upcoming it={matchData} />) : recent ? <Recent it={matchData} /> : null}
      </div> : null


  )
}

export default PinkScoreCard
